<script lang="ts" setup>
import { allProjects } from '~/pages/projekte.vue'
import { projectKey } from '~/types/inject'

import colors from '#tailwind-config/theme/colors'

defineSlots<{
  default: void
}>()

const project = inject(projectKey)

const projectIndex = computed(() =>
  allProjects.findIndex(([p]) => p.slug === project?.value?.value.slug),
)
const nextProject = computed(() => allProjects[(projectIndex.value + 1) % allProjects.length][0])

useSeoMeta({
  themeColor: colors['elbworx-yellow'],
})

const route = useRoute()
</script>

<template>
  <div class="flex min-h-screen flex-col text-elbworx-black">
    <Navbar />
    <div>
      <slot />
    </div>
    <ScrollToTopButton />

    <PageFooter v-if="!route.name?.toString().startsWith('projekt-')" />
    <div
      v-else
      class="group h-[calc(var(--tilt-height-container)*2+3rem)] overflow-hidden pt-tilt-container sm:h-[calc(var(--tilt-height-container)*2+1rem)]"
    >
      <ElbworxLink
        :href="`/projekt/${nextProject.slug}/`"
        class="relative block transition-transform duration-500 ease-in-out group-hover:-translate-y-[calc(var(--tilt-height-container)*0.6)]"
      >
        <Tilt class="relative h-[--project-header-height]">
          <BackgroundMedia :background-image="nextProject.header.background.image" />
        </Tilt>
        <div class="absolute max-sm:inset-0 max-sm:text-center sm:right-0 sm:top-0">
          <Padding>
            <PageHeadline class="-translate-y-1/4 !text-6xl sm:pr-16 sm:!text-7xl md:!text-8xl">
              noch eins
            </PageHeadline>
          </Padding>
        </div>
      </ElbworxLink>
    </div>
  </div>
</template>
